try {
    window.bootstrap = require ('bootstrap');
} catch (e) {}

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
