require('./bootstrap');

/* JS EFFET NAVBAR AU TOP (version STUCK) */
(new IntersectionObserver(function (e) {
  if (e[0].intersectionRatio > 0) {
    document.documentElement.removeAttribute('class');
  } else {
    document.documentElement.setAttribute('class', 'stuck');
  };
})).observe(document.querySelector('.trigger'));

/* FOR MOBILE ONLY */
if (window.matchMedia("(max-width: 991px)").matches) {
  const navLinks = document.querySelectorAll('.nav-item:not(.dropdown)');
  const menuToggle = document.getElementById('navbarToggler');
  const bsCollapse = new bootstrap.Collapse(menuToggle, {toggle: false});
  navLinks.forEach((l) => {
    l.addEventListener('click', () => { bsCollapse.toggle() })
  });
}

/*
* SCROLL ANIME
* https://github.com/michalsnik/aos
*/

/* FOR DESKTOP ONLY */
if (window.matchMedia("(min-width: 992px)").matches) {

  /* DISPLAY BS4 TOOLTIP */
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  })

  /* GO TOP */
  document.addEventListener("scroll", handleScroll);
  var scrollToTopBtn = document.querySelector(".scrollToTopBtn");

  function handleScroll() {
    var scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var GOLDEN_RATIO = 0.5;
    if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
      if (!scrollToTopBtn.classList.contains("showScrollBtn"))
        scrollToTopBtn.classList.add("showScrollBtn")
    } else {
      if (scrollToTopBtn.classList.contains("showScrollBtn"))
        scrollToTopBtn.classList.remove("showScrollBtn")
    }
  }
  scrollToTopBtn.addEventListener("click", scrollToTop);

  function scrollToTop() {
    window.scrollTo({
      right: 0,
      behavior: "smooth"
    });
  }

  /*
  WRAP IMG
  document.querySelectorAll('#main img').outerHTML

  document.querySelectorAll("#main img").forEach(currentImg => {
    currentImg.style.display = "none"
    org_html = document.getElementById("slidesContainer").innerHTML;
    new_html = "<div id='slidesInner'>" + org_html + "</div>";
    document.getElementById("slidesContainer").innerHTML = new_html;
  });
  */
};

/* ANTI NAVIGATEURS ANCIENS */
let showBrowserAlert = (function () {
  if (document.querySelector('.old-browser')) {
    let d = document.getElementsByClassName('old-browser');

    window.browser = {};

    if (/edge\/[0-9]{2}/i.test(navigator.userAgent)) {
      browser.agent = "Edge";
      browser.majorVersion = parseInt(/edge\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      browser.old = 1;
    } else if (/edg\/[0-9]{2}/i.test(navigator.userAgent)) {
      browser.agent = "Edge (basé Chromium)";
      browser.majorVersion = parseInt(/edg\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      if (browser.majorVersion < 79) {
        browser.old = 1;
      }
    } else if (/chrome\/[0-9]{2}/i.test(navigator.userAgent)) {
      browser.agent = "Chrome";
      browser.majorVersion = parseInt(/chrome\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      if (browser.majorVersion < 78) {
        browser.old = 1;
      }
    } else if (/firefox\/[0-9]{2}/i.test(navigator.userAgent)) {
      browser.agent = "Firefox";
      browser.majorVersion = parseInt(/firefox\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      if (browser.majorVersion < 72) {
        browser.old = 1;
      }
    } else if (/msie\ [0-9]{1}/i.test(navigator.userAgent)) {
      browser.agent = "Internet Explorer";
      browser.majorVersion = parseInt(/MSIE\ ([0-9]{1})/i.exec(navigator.userAgent)[1]);
      browser.old = 1;
    } else if (/opr\/[0-9]{2}/i.test(navigator.userAgent)) {
      browser.agent = "Opera";
      browser.majorVersion = parseInt(/opr\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      if (browser.majorVersion < 65) {
        browser.old = 1;
      }
    } else if (/Trident\/[7]{1}/i.test(navigator.userAgent)) {
      browser.agent = "Internet Explorer";
      browser.majorVersion = 11;
      browser.old = 1;
    } else if (/Safari\/[0-9.]+/i.test(navigator.userAgent)) {
      browser.agent = "Safari";
      browser.majorVersion = parseInt(/Version\/([0-9]{2})/i.exec(navigator.userAgent)[1]);
      if (browser.majorVersion < 12) {
        browser.old = 1;
      }
    } else {
      browser.agent = false;
      browser.majorVersion = false;
    }

    if (browser.old >= 1) {
      let msg = '<div class="position-absolute alert alert-warning alert-dismissible mt-3" style="right: 1rem; z-index: 9999;">' +
      '<h2 class="mb-0">Votre navigateur ' + browser.agent + ' ' + browser.majorVersion + '</h2>' +
      '<h3>est obsolète !</h3>' +
      '<p class="mb-0">Mettez-le à jour pour naviguer plus en sécurité et<br>profiter des dernières avancées techologiques</p><hr>' +
      '<p>Les principaux navigateurs modernes<br><small>Cliquez une icône pour accéder à la page de téléchargement officiel du navigateur</small></p>' +
      '<a href="https://www.microsoft.com/fr-fr/edge" target="_blank" rel="noopener"><svg style="height: 6rem; fill: #1192f4;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.86 15.37C10.17 14.6 9.7 13.68 9.55 12.65C9.25 13.11 9 13.61 8.82 14.15C7.9 16.9 9.5 20.33 12.22 21.33C14.56 22.11 17.19 20.72 18.92 19.2C19.18 18.85 21.23 17.04 20.21 16.84C17.19 18.39 13.19 17.95 10.86 15.37M11.46 9.56C12.5 9.55 11.5 9.13 11.07 8.81C10.03 8.24 8.81 7.96 7.63 7.96C3.78 8 .995 10.41 2.3 14.4C3.24 18.28 6.61 21.4 10.59 21.9C8.54 20.61 7.3 18.19 7.3 15.78C7.38 13.25 8.94 10.28 11.46 9.56M2.78 8.24C5.82 6 10.66 6.18 13.28 9C14.3 10.11 15 12 14.07 13.37C12.33 15.25 17.15 15.5 18.18 15.22C21.92 14.5 22.91 10.15 21.13 7.15C19.43 3.75 15.66 1.97 11.96 2C7.9 1.93 4.25 4.5 2.78 8.24Z"/></svg></a>' +
      '<a href="https://www.google.com/chrome/" target="_blank" rel="noopener"><svg style="height: 6rem; fill: #f9c535;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,20L15.46,14H15.45C15.79,13.4 16,12.73 16,12C16,10.8 15.46,9.73 14.62,9H19.41C19.79,9.93 20,10.94 20,12A8,8 0 0,1 12,20M4,12C4,10.54 4.39,9.18 5.07,8L8.54,14H8.55C9.24,15.19 10.5,16 12,16C12.45,16 12.88,15.91 13.29,15.77L10.89,19.91C7,19.37 4,16.04 4,12M15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12M12,4C14.96,4 17.54,5.61 18.92,8H12C10.06,8 8.45,9.38 8.08,11.21L5.7,7.08C7.16,5.21 9.44,4 12,4M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/></svg></a>' +
      '<a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener"><svg style="height: 6rem; fill: #f36629;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.27 7.94C9.27 7.94 9.27 7.94 9.27 7.94M6.85 6.74C6.86 6.74 6.86 6.74 6.85 6.74M21.28 8.6C20.85 7.55 19.96 6.42 19.27 6.06C19.83 7.17 20.16 8.28 20.29 9.1L20.29 9.12C19.16 6.3 17.24 5.16 15.67 2.68C15.59 2.56 15.5 2.43 15.43 2.3C15.39 2.23 15.36 2.16 15.32 2.09C15.26 1.96 15.2 1.83 15.17 1.69C15.17 1.68 15.16 1.67 15.15 1.67H15.13L15.12 1.67L15.12 1.67L15.12 1.67C12.9 2.97 11.97 5.26 11.74 6.71C11.05 6.75 10.37 6.92 9.75 7.22C9.63 7.27 9.58 7.41 9.62 7.53C9.67 7.67 9.83 7.74 9.96 7.68C10.5 7.42 11.1 7.27 11.7 7.23L11.75 7.23C11.83 7.22 11.92 7.22 12 7.22C12.5 7.21 12.97 7.28 13.44 7.42L13.5 7.44C13.6 7.46 13.67 7.5 13.75 7.5C13.8 7.54 13.86 7.56 13.91 7.58L14.05 7.64C14.12 7.67 14.19 7.7 14.25 7.73C14.28 7.75 14.31 7.76 14.34 7.78C14.41 7.82 14.5 7.85 14.54 7.89C14.58 7.91 14.62 7.94 14.66 7.96C15.39 8.41 16 9.03 16.41 9.77C15.88 9.4 14.92 9.03 14 9.19C17.6 11 16.63 17.19 11.64 16.95C11.2 16.94 10.76 16.85 10.34 16.7C10.24 16.67 10.14 16.63 10.05 16.58C10 16.56 9.93 16.53 9.88 16.5C8.65 15.87 7.64 14.68 7.5 13.23C7.5 13.23 8 11.5 10.83 11.5C11.14 11.5 12 10.64 12.03 10.4C12.03 10.31 10.29 9.62 9.61 8.95C9.24 8.59 9.07 8.42 8.92 8.29C8.84 8.22 8.75 8.16 8.66 8.1C8.43 7.3 8.42 6.45 8.63 5.65C7.6 6.12 6.8 6.86 6.22 7.5H6.22C5.82 7 5.85 5.35 5.87 5C5.86 5 5.57 5.16 5.54 5.18C5.19 5.43 4.86 5.71 4.56 6C4.21 6.37 3.9 6.74 3.62 7.14C3 8.05 2.5 9.09 2.28 10.18C2.28 10.19 2.18 10.59 2.11 11.1L2.08 11.33C2.06 11.5 2.04 11.65 2 11.91L2 11.94L2 12.27L2 12.32C2 17.85 6.5 22.33 12 22.33C16.97 22.33 21.08 18.74 21.88 14C21.9 13.89 21.91 13.76 21.93 13.63C22.13 11.91 21.91 10.11 21.28 8.6Z"/></svg></a>' +
      '<a href="https://www.opera.com/" target="_blank" rel="noopener"><svg style="height: 6rem; fill: #eb0020;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.04,17.07C8.04,15.9 7.4,14.16 7.35,12.21V11.79C7.4,9.84 8.04,8.1 9.04,6.93C9.86,5.95 10.93,5.37 12.1,5.37C14.72,5.37 16.84,8.34 16.84,12C16.84,15.66 14.72,18.63 12.1,18.63C10.93,18.63 10.33,18.5 9.04,17.07M12.03,3H12A9,9 0 0,0 3,12C3,16.83 6.8,20.77 11.57,21H12C14.3,21 16.4,20.13 18,18.71C19.84,17.06 21,14.67 21,12C21,9.33 19.84,6.94 18,5.29C16.41,3.87 14.32,3 12.03,3Z"/></svg></a>' +
      '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>';
      d[0].innerHTML = msg;
      d[0].style.display = 'block';
    } else {
      let msg = '';
      d[0].innerHTML = msg;
      d[0].style.display = 'none';
    }
  }
});
document.addEventListener('DOMContentLoaded', showBrowserAlert);
